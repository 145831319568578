import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import DashbordPage from '@/components/pages/DashboardPage'

function Dashboard() {
	return (
		<div className='relative elrond-background'>
			<DashbordPage></DashbordPage>
		</div>
	)
}

export default Dashboard
