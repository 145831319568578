import { IntegrationAPI } from '@/services/axios'

export default {
	Init(request, config) {
		return IntegrationAPI.post('/api/users/init', request, config)
	},
	Login(data, config) {
		return IntegrationAPI.post('/api/users/login', data, config)
	},
	Me(data) {
		return IntegrationAPI.post('/api/users/me', data)
	},
	Logout() {
		return IntegrationAPI.post('/api/users/logout')
	},
	Signup(request, config) {
		return IntegrationAPI.post('/api/users/signup', request, config)
	},
	UpdatePersonalInformation(body, config) {
		return IntegrationAPI.post('/api/users/personal', body, config)
	},
	UpdateAdditionalInformation(body, config) {
		return IntegrationAPI.post('/api/users/additional', body, config)
	},
	UpdateVideo(body, config) {
		return IntegrationAPI.post('/api/users/video', body, config)
	},
	Undo(body, config) {
		return IntegrationAPI.post('/api/users/undo', body, config)
	},
}
