import { useContext, useEffect, useState } from 'react'
import { LoginContext } from '@/context/login'
import SubNav from '../menu/SubNav'
import Footer from '../menu/Footer'
import SynapsClient from '@synaps-io/verify.js'
import AuthService from '@/services/auth'
import Hooks from '@/components/tool/Hooks'
import { AuthContext, AuthContextProvider } from '@/context/auth'
import WorkflowSteps from "./WorkflowSteps";
import Verification from "./Verification";
import Additional from "./Additional";
import Personal from "./Personal";
import { useHistory } from 'react-router-dom'

export default function DashboardPage() {
	const [loginState, loginDispatch] = useContext(LoginContext)
	const [, authDispatch] = useContext(AuthContext)
	const [ready, setReady] = useState(false)
	const [stepID, setStepID] = useState(0)
	const width = Hooks()

	const router = useHistory()
	const query = new URLSearchParams(router.location.search)
	const key = query.get('key')

	const INDIVUDUAL_STEPS_LIST = {
		0: "personal",
		1: "additional",
		2: "KYC",
	}

	useEffect(async () => {
		const body = {
			key: key,
		}

		AuthService.Me(body)
			.then((data) => {
				authDispatch({
					type: 'SET_AUTH',
					payload: {
						user: {
							email: data.email,
							firstname: data.firstname,
							lastname: data.lastname,
							profile_pic: data.profile_pic,
						},
						iam: data.iam,
						authenticated: true,
					},
				})

				if (data.steps.personal === false) {
					setStepID(0)
				} else if (data.steps.additional === false) {
					setStepID(1)
				} else {
					setStepID(2)
				}

				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							session_id: data.session,
							account_type: data.session_type,
							additional: data.steps.additional,
							investment: data.steps.investment,
							kyc: data.steps.kyc,
							personal: data.steps.personal,
						},
					},
				})

				setReady(true)
			})
			.catch((err) => {
				authDispatch({
					type: 'SET_AUTH',
					payload: {
						user: {
							email: '',
							firstname: '',
							lastname: '',
							profile_pic: '',
						},
						iam: [],
						authenticated: false,
					},
				})
				setReady(true)
			})
		return () => { }

	}, [])

	return (
		<div>
			{ready ?
				<div>
					<WorkflowSteps current={INDIVUDUAL_STEPS_LIST[stepID]} additional={loginState.auth.additional} personal={loginState.auth.personal} investment={loginState.auth.investment} kyc={loginState.auth.kyc}></WorkflowSteps>
					<div>
						{INDIVUDUAL_STEPS_LIST[stepID] === "personal" ?
							<Personal onNext={() => setStepID(stepID + 1)}></Personal>
							: null}
						{INDIVUDUAL_STEPS_LIST[stepID] === "additional" ?
							<Additional onBack={() => setStepID(stepID - 1)} onNext={() => setStepID(stepID + 1)}></Additional>
							: null}
						{INDIVUDUAL_STEPS_LIST[stepID] === "KYC" ?
							<Verification onBack={() => setStepID(stepID - 1)}></Verification>
							: null}
					</div>
					<div className='max-w-7xl mx-auto relative'>
						<Footer></Footer>
					</div>
				</div> : null}
		</div>
	)
}
