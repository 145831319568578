import { useContext, useEffect, useState } from 'react'
import { LoginContext } from '@/context/login'
import {
	faCheckCircle,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '../ui/Button'
import SubNav from '../menu/SubNav'
import Footer from '../menu/Footer'
import SynapsClient from '@synaps-io/verify.js'
import AuthService from '@/services/auth'
import Hooks from '@/components/tool/Hooks'
import { AuthContext, AuthContextProvider } from '@/context/auth'
import {
	faPassport,
} from '@fortawesome/pro-duotone-svg-icons'
import { useHistory } from 'react-router-dom'

export default function Verification(props) {
	const [loginState, loginDispatch] = useContext(LoginContext)
	const [, authDispatch] = useContext(AuthContext)
	const [ready, setReady] = useState(false)
	const width = Hooks()
	const [loading, setLoading] = useState(false)

	const router = useHistory()
	const query = new URLSearchParams(router.location.search)
	const key = query.get('key')

	function InitSynaps() {
		const Synaps = new SynapsClient(loginState.auth.session_id, loginState.auth.account_type.toLowerCase())
		Synaps.init({
			type: 'embed',
			colors: {
				primary: '000000',
				secondary: 'FFFFFF'
			}
		})

		Synaps.on('finish', () => {
			var payload = {
				type: 'finish',
				data: {},
			}
			window.parent.postMessage(payload, '*')
		})
	}

	useEffect(async () => {
		if (ready === true) {
			InitSynaps()
		}
	}, [ready])

	useEffect(async () => {
		const body = {
			key: key,
		}


		AuthService.Me(body)
			.then((data) => {
				authDispatch({
					type: 'SET_AUTH',
					payload: {
						user: {
							email: data.email,
							firstname: data.firstname,
							lastname: data.lastname,
							profile_pic: data.profile_pic,
						},
						iam: data.iam,
						authenticated: true,
					},
				})

				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							session_id: data.session,
							account_type: data.session_type,
							additional: data.steps.additional,
							investment: data.steps.investment,
							kyc: data.steps.kyc,
							personal: data.steps.personal,
						},
					},
				})

				setReady(true)
			})
			.catch((err) => {
				console.log(err)
				authDispatch({
					type: 'SET_AUTH',
					payload: {
						user: {
							email: '',
							firstname: '',
							lastname: '',
							profile_pic: '',
						},
						iam: [],
						authenticated: false,
					},
				})
				setReady(true)
			})
		return () => { }
	}, [])

	async function goBack() {
		setLoading(true)
		let bodyFormData = new FormData()
		bodyFormData.append('step', 'ADDITIONAL')

		const config = {
			headers: {
				Token: key,
			},
		}

		await AuthService.Undo(bodyFormData, config)
			.then((data) => {
				setLoading(false)
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							session_id: loginState.auth.session_id,
							account_type: loginState.auth.account_type,
							additional: loginState.auth.additional,
							investment: false,
							kyc: loginState.auth.kyc,
							personal: loginState.auth.personal,
						},
					},
				})
				props.onBack()
			})
			.catch((err) => {
				setLoading(false)
			})
		return () => { }
	}

	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className='max-w-7xl mx-auto relative'>
					<div className='dashboard-container'>
						<div className='mx-auto'>
							<div className='flex justify-between '>
								<div></div>
								<div className='mx-auto bg-white mr-5 ml-5 rounded-xl synaps p-4 shadow'>
									<div className='synaps-modal' id="synaps-embed"></div>
								</div>
								<div></div>
							</div>

							<div className=" mr-5 ml-5 mx-auto">
								<div className='bg-white rounded-xl p-6 mt-5 shadow'>
									<div className='flex justify-between'>
										<div>
											<Button
												type='submit'
												full
												loading={loading}
												label='Back'
												onClick={() => goBack()}
											/>
										</div>
										<div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div >
	)
}
