import { useContext, useEffect, useState } from 'react'
import { LoginContext } from '@/context/login'
import {
    faCheckCircle, faDollarSign, faEnvelope, faUser,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Input from '@/components/ui/Input'

import Button from '../ui/Button'
import SubNav from '../menu/SubNav'
import AuthService from '@/services/auth'
import Hooks from '@/components/tool/Hooks'
import { AuthContext, AuthContextProvider } from '@/context/auth'
import Upload from '../ui/Upload'
import { useForm } from 'react-hook-form'
import CountrySelector from '@/components/ui/CountrySelector'
import { useHistory } from 'react-router-dom'

export default function Investment(props) {
    const [loginState, loginDispatch] = useContext(LoginContext)
    const [, authDispatch] = useContext(AuthContext)
    const [ready, setReady] = useState(false)
    const width = Hooks()
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, errors } = useForm()
    const [disable, setDisable] = useState(true)
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [nationality, setNationality] = useState("")
    const [birth, setBirth] = useState("")
    const [country, setCountry] = useState("")
    const [nationalitySuggest, setNationalitySuggest] = useState("")
    const [countrySuggest, setCountrySuggest] = useState("")

    const restrictions_nationality = [
        "BLR",
        "RUS",
    ]

    const restrictions_country = [
        "AFG",
        "ALB",
        "BRB",
        "BLR",
        "BFA",
        "BDI",
        "KHM",
        "CMR",
        "CYM",
        "CAF",
        "TCD",
        "CHL",
        "COL",
        "COD",
        "EGY",
        "GIN",
        "GNB",
        "HTI",
        "IRN",
        "IRQ",
        "JAM",
        "JOR",
        "KEN",
        "PRK",
        "LBN",
        "LBY",
        "MLI",
        "MAR",
        "MOZ",
        "MMR",
        "NIC",
        "NER",
        "NGA",
        "PAK",
        "PAN",
        "PHL",
        "RUS",
        "SEN",
        "SOM",
        "SSD",
        "SDN",
        "SYR",
        "TTO",
        "TUN",
        "UGA",
        "UKR",
        "ARE",
        "USA",
        "VUT",
        "VEN",
        "YEM",
        "ZWE",
    ]

    const router = useHistory()
    const query = new URLSearchParams(router.location.search)
    const key = query.get('key')

    useEffect(async () => { }, [ready])

    useEffect(async () => {
        const body = {
            key: key,
        }


        AuthService.Me(body)
            .then((data) => {
                setFirstname(data.personal.firstname)
                setLastname(data.personal.lastname)
                setNationalitySuggest(data.personal.nationality)
                setNationality(data.personal.nationality)
                setCountrySuggest(data.personal.country)
                setCountry(data.personal.country)
                setBirth(data.personal.birth)

                authDispatch({
                    type: 'SET_AUTH',
                    payload: {
                        user: {
                            email: data.email,
                            firstname: data.firstname,
                            lastname: data.lastname,
                            profile_pic: data.profile_pic,
                        },
                        iam: data.iam,
                        authenticated: true,
                    },
                })

                loginDispatch({
                    type: 'SET_LOGIN',
                    payload: {
                        auth: {
                            session_id: data.session,
                            account_type: data.session_type,
                            additional: data.steps.additional,
                            investment: data.steps.investment,
                            kyc: data.steps.kyc,
                            personal: data.steps.personal,
                        },
                    },
                })

                setReady(true)
            })
            .catch((err) => {
                authDispatch({
                    type: 'SET_AUTH',
                    payload: {
                        user: {
                            email: '',
                            firstname: '',
                            lastname: '',
                            profile_pic: '',
                        },
                        iam: [],
                        authenticated: false,
                    },
                })
                setReady(true)
            })
        return () => { }
    }, [])

    const onSubmit = async (data) => {
        setError("")
        setLoading(true)

        if (firstname === "" || lastname === "" || nationality === "" || birth === "" || country === "") {
            setError("Missing Required Information")
            setLoading(false)
            return
        }

        let body = {
            'firstname': firstname,
            'lastname': lastname,
            'nationality': nationality,
            'birth': birth,
            'country': country,
        }

        const config = {
            headers: {
                Token: key,
            },
        }

        await AuthService.UpdatePersonalInformation(body, config)
            .then((data) => {
                setLoading(false)
                loginDispatch({
                    type: 'SET_LOGIN',
                    payload: {
                        auth: {
                            session_id: loginState.auth.session_id,
                            account_type: loginState.auth.account_type,
                            additional: loginState.auth.additional,
                            investment: true,
                            kyc: loginState.auth.kyc,
                            personal: true,
                        },
                    },
                })
                props.onNext()
            })
            .catch((err) => {
                setLoading(false)
                setError(err.message)
            })
        return () => { }

    }

    function UpdateFirstname(value) {
        setError("")
        if (value === "") {
            setFirstname("")
            setDisable(true)
        } else {
            setFirstname(value)
            if (firstname !== "" && lastname !== "" && nationality !== "" && birth !== "" && country !== "") {
                setDisable(false)
            }
        }
    }

    function UpdateLastname(value) {
        setError("")
        if (value === "") {
            setLastname("")
            setDisable(true)
        } else {
            setLastname(value)
            if (firstname !== "" && lastname !== "" && nationality !== "" && birth !== "" && country !== "") {
                setDisable(false)
            }
        }
    }

    function UpdateNationality(value) {
        setError("")
        if (value.name === "") {
            setNationalitySuggest("")
            setNationality("")
            setDisable(true)
        } else {
            if (value.alpha3 !== "" && value.alpha3 !== undefined) {
                setNationality(value.alpha3)

            }
            setNationalitySuggest(value.name)
            if (firstname !== "" && lastname !== "" && nationality !== "" && birth !== "" && country !== "") {
                setDisable(false)
            }
        }
    }

    function UpdateBirth(value) {
        setError("")
        if (value.target.value === "") {
            setBirth("")
            setDisable(true)
        } else {
            setBirth(value.target.value)
            if (firstname !== "" && lastname !== "" && nationality !== "" && birth !== "" && country !== "") {
                setDisable(false)
            }
        }
    }



    function UpdateCountry(value) {
        setError("")
        if (value === "") {
            setCountrySuggest("")
            setCountry("")
            setDisable(true)
        } else {
            if (value.alpha3 !== "" && value.alpha3 !== undefined) {
                setCountry(value.alpha3)
            }
            setCountrySuggest(value.name)
            if (firstname !== "" && lastname !== "" && nationality !== "" && birth !== "" && country !== "") {
                setDisable(false)
            }
        }
    }


    return (
        <div>
            {ready ?
                <div>
                    <SubNav></SubNav>
                    <div className='max-w-7xl mx-auto relative'>
                        <div className='dashboard-container'>
                            <div className='bg-white rounded-xl p-6 shadow'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='flex justify-center'>
                                        <div className='w-1/2 mr-5'>
                                            <Input
                                                setValue={(e) => UpdateFirstname(e)}
                                                icon={faUser}
                                                label='Firstname'
                                                placeholder='John-Michal'
                                                name='firstname'
                                                value={firstname}
                                            />
                                        </div>

                                        <div className='w-1/2 ml-5'>
                                            <Input
                                                setValue={(e) => UpdateLastname(e)}
                                                icon={faUser}
                                                label='Lastname'
                                                placeholder='Smith'
                                                name='lastname'
                                                value={lastname}
                                            />
                                        </div>
                                    </div>

                                    <div className='flex justify-center mt-5'>

                                        <div className='w-1/2 mr-5'>
                                            <label
                                                className="block text-sm font-medium text-gray-700 mb-2">
                                                Date of Birth
                                            </label>

                                            <div className="appearance-none block w-full py-2.5 border transition duration-100 ease-in border-gray-300 rounded-xl placeholder-gray-400 focus:outline-none sm:text-sm">
                                                <input className='ml-5'
                                                    type="date"
                                                    value={birth}
                                                    onChange={(e) => UpdateBirth(e)} />
                                            </div>
                                        </div>

                                        <div className='w-1/2 ml-5'>
                                            <label
                                                className="block text-sm mb-3 font-medium text-primary-700">
                                                Nationality
                                            </label>
                                            <CountrySelector value={nationalitySuggest} restricted={restrictions_nationality} setValue={(value) => UpdateNationality(value)} />
                                        </div>
                                    </div>

                                    <div className='flex justify-center mt-5'>
                                        <div className='w-1/2 mr-5'>
                                            <label
                                                className="block text-sm mb-3 font-medium text-primary-700">
                                                Country of Residence
                                            </label>
                                            <CountrySelector value={countrySuggest} restricted={restrictions_country} setValue={(value) => UpdateCountry(value)} />
                                        </div>

                                        <div className='w-1/2 ml-5'>
                                        </div>
                                    </div>


                                    {error !== "" ? <p className="mt-2 text-sm text-red-600" id="email-error">
                                        {error}
                                    </p> : null}

                                    <div className='flex justify-between mt-10'>
                                        <div>
                                        </div>
                                        <div>
                                            <Button
                                                type='submit'
                                                full
                                                loading={loading}
                                                label='Next'
                                                onClick={() => onSubmit()}
                                            />
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </div>
    )
}
