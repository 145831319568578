import { useContext, useEffect, useState } from 'react'
import { LoginContext } from '@/context/login'
import {
	faCheckCircle, faDollarSign, faEnvelope, faUser, faWallet,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Input from '@/components/ui/Input'

import Button from '../ui/Button'
import SubNav from '../menu/SubNav'
import AuthService from '@/services/auth'
import Hooks from '@/components/tool/Hooks'
import { AuthContext, AuthContextProvider } from '@/context/auth'
import Upload from '../ui/Upload'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

export default function Investment(props) {
	const [loginState, loginDispatch] = useContext(LoginContext)
	const [, authDispatch] = useContext(AuthContext)
	const [ready, setReady] = useState(false)
	const width = Hooks()
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, errors } = useForm()
	const [disable, setDisable] = useState(true)
	const [job, setJob] = useState("")

	const [otherFunds, setOtherFunds] = useState(false)
	const [savingsFunds, setSavingsFunds] = useState(false)
	const [profitsFunds, setProfitsFunds] = useState(false)
	const [inheritanceFunds, setInheritanceFunds] = useState(false)
	const [saleFunds, setSaleFunds] = useState(false)

	const [otherWealth, setOtherWealth] = useState(false)
	const [savingsWealth, setSavingsWealth] = useState(false)
	const [profitsWealth, setProfitsWealth] = useState(false)
	const [inheritanceWealth, setInheritanceWealth] = useState(false)
	const [saleWealth, setSaleWealth] = useState(false)

	const [otherIntend, setOtherIntend] = useState(false)
	const [investmentIntend, setInvestmentIntend] = useState(false)

	const router = useHistory()
	const query = new URLSearchParams(router.location.search)
	const key = query.get('key')


	const [other1, setOther1] = useState("")
	const [other2, setOther2] = useState("")
	const [other3, setOther3] = useState("")

	useEffect(async () => { }, [ready])

	useEffect(async () => {

		const body = {
			key: key,
		}


		AuthService.Me(body)
			.then((data) => {
				setJob(data.additional.job)
				if (data.additional.funds.includes("Savings from regular income")) {
					setSavingsFunds(true)
				}
				if (data.additional.funds.includes("Profits from previous investments")) {
					setProfitsFunds(true)
				}
				if (data.additional.funds.includes("Inheritance")) {
					setInheritanceFunds(true)
				}
				if (data.additional.funds.includes("Sale of assets (real estate / art etc.)")) {
					setSaleFunds(true)
				}

				if (data.additional.wealth.includes("Savings from regular income")) {
					setSaleWealth(true)
				}
				if (data.additional.wealth.includes("Profits from previous investments")) {
					setProfitsWealth(true)
				}
				if (data.additional.wealth.includes("Inheritance")) {
					setInheritanceWealth(true)
				}
				if (data.additional.wealth.includes("Sale of assets (real estate / art etc.)")) {
					setSaleWealth(true)
				}

				if (data.additional.assets.includes("Investment")) {
					setInvestmentIntend(true)
				}

				if (data.additional.other_wealth !== "") {
					setOtherWealth(true)
					setOther2(data.additional.other_wealth)
				}

				if (data.additional.other_funds !== "") {
					setOtherFunds(true)
					setOther1(data.additional.other_funds)
				}

				if (data.additional.other_assets !== "") {
					setOtherIntend(true)
					setOther3(data.additional.other_assets)
				}

				authDispatch({
					type: 'SET_AUTH',
					payload: {
						user: {
							email: data.email,
							firstname: data.firstname,
							lastname: data.lastname,
							profile_pic: data.profile_pic,
						},
						iam: data.iam,
						authenticated: true,
					},
				})

				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							session_id: data.session,
							account_type: data.session_type,
							additional: data.steps.additional,
							investment: data.steps.investment,
							kyc: data.steps.kyc,
							personal: data.steps.personal,
						},
					},
				})

				setReady(true)
			})
			.catch((err) => {
				authDispatch({
					type: 'SET_AUTH',
					payload: {
						user: {
							email: '',
							firstname: '',
							lastname: '',
							profile_pic: '',
						},
						iam: [],
						authenticated: false,
					},
				})
				setReady(true)
			})

		return () => { }
	}, [])

	const onSubmit = async (data) => {
		setError("")
		setLoading(true)


		let fundsArray = []
		let wealthArray = []
		let intendArray = []
		if (savingsFunds === false && profitsFunds === false && inheritanceFunds === false && saleFunds === false && otherFunds === true && other1 === "") {
			setError("Missing Required Information")
			setLoading(false)
			return
		}

		if (savingsWealth === false && profitsWealth === false && inheritanceWealth === false && saleWealth === false && otherWealth === true && other2 === "") {
			setError("Missing Required Information")
			setLoading(false)
			return
		}

		if (investmentIntend == false && otherIntend === true && other3 === "") {
			setError("Missing Required Information")
			setLoading(false)
			return
		}

		if (savingsFunds) {
			fundsArray.push("Savings from regular income")
		}

		if (inheritanceFunds) {
			fundsArray.push("Profits from previous investments")
		}

		if (profitsFunds) {
			fundsArray.push("Inheritance")
		}

		if (saleFunds) {
			fundsArray.push("Sale of assets (real estate / art etc.)")
		}

		if (savingsWealth) {
			wealthArray.push("Savings from regular income")
		}

		if (inheritanceWealth) {
			wealthArray.push("Profits from previous investments")
		}

		if (profitsWealth) {
			wealthArray.push("Inheritance")
		}

		if (saleWealth) {
			wealthArray.push("Sale of assets (real estate / art etc.)")
		}

		if (investmentIntend) {
			intendArray.push("Investment")
		}

		if (job === "" || intendArray.length === 0 || wealthArray.length === 0 || fundsArray.length === 0) {
			setError("Missing Required Information")
			setLoading(false)
			return
		}

		let body = {
			'job': job,
			'funds': fundsArray,
			'wealth': wealthArray,
			'assets': intendArray,
			'other_funds': other1,
			'other_wealth': other2,
			'other_assets': other3,
		}

		const config = {
			headers: {
				Token: key,
			},
		}

		await AuthService.UpdateAdditionalInformation(body, config)
			.then((data) => {
				setLoading(false)
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							session_id: loginState.auth.session_id,
							account_type: loginState.auth.account_type,
							additional: true,
							investment: true,
							kyc: loginState.auth.kyc,
							personal: loginState.auth.personal,
						},
					},
				})
				props.onNext()
			})
			.catch((err) => {
				setLoading(false)
				setError(err.message)
			})
		return () => { }
	}

	function UpdateJob(value) {
		setError("")
		if (value === "") {
			setJob("")
		} else {
			setJob(value)
		}
	}

	async function goBack() {
		setLoading(true)
		let bodyFormData = new FormData()
		bodyFormData.append('step', 'PERSONAL')

		const config = {
			headers: {
				Token: key,
			},
		}

		await AuthService.Undo(bodyFormData, config)
			.then((data) => {
				setLoading(false)
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							session_id: loginState.auth.session_id,
							account_type: loginState.auth.account_type,
							additional: false,
							investment: loginState.auth.investment,
							kyc: loginState.auth.kyc,
							personal: loginState.auth.personal,
						},
					},
				})
				props.onBack()
			})
			.catch((err) => {
				setLoading(false)
				setError(err.message)
			})
		return () => { }
	}

	return (
		<div>
			{ready ?
				<div>
					<SubNav></SubNav>
					<div className='max-w-7xl mx-auto relative'>
						<div className='dashboard-container'>
							<div className='bg-white rounded-xl p-6 shadow'>
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className='flex justify-center'>
										<div className='w-full mr-5'>
											<Input
												setValue={(e) => UpdateJob(e)}
												icon={faUser}
												label='Job Title'
												placeholder='Please input your profession title'
												name='job'
												value={job}
											/>
										</div>
									</div>

									<div className='mt-5'>
										<label
											className="block text-sm font-medium text-gray-700 mb-2">
											Description on the Source of Funds
										</label>
										<div>
											<label className="checktainer flex mr-5">Savings from regular income
												<input className="checkbox mr-3 ml-2 mt-1"
													type="checkbox"
													checked={savingsFunds}
													onChange={() => setSavingsFunds(!savingsFunds)} />
												<span className="checkmark block text-sm mb-3 font-medium text-primary-700"></span>
											</label>
											<label className="checktainer flex mr-5">Profits from previous investments
												<input className="checkbox mr-3 ml-2 mt-1"
													type="checkbox"
													checked={profitsFunds}
													onChange={() => setProfitsFunds(!profitsFunds)} />
												<span className="checkmark block text-sm mb-3 font-medium text-primary-700"></span>
											</label>
											<label className="checktainer flex mr-5">Inheritance
												<input className="checkbox mr-3 ml-2 mt-1"
													type="checkbox"
													checked={inheritanceFunds}
													onChange={() => setInheritanceFunds(!inheritanceFunds)} />
												<span className="checkmark block text-sm mb-3 font-medium text-primary-700"></span>
											</label>
											<label className="checktainer flex mr-5">Sale of assets (real estate / art etc.)
												<input className="checkbox mr-3 ml-2 mt-1"
													type="checkbox"
													checked={saleFunds}
													onChange={() => setSaleFunds(!saleFunds)} />
												<span className="checkmark block text-sm mb-3 font-medium text-primary-700"></span>
											</label>
											<label className="checktainer flex mr-5">Other
												<input className="checkbox mr-3 ml-2 mt-1"
													type="checkbox"
													checked={otherFunds}
													onChange={() => setOtherFunds(!otherFunds)} />
												<span className="checkmark block text-sm mb-3 font-medium text-primary-700"></span>
											</label>
										</div>
									</div>

									{otherFunds ? <>
										<div className='flex justify-center mb-3'>
											<div className='w-full mr-5'>
												<Input
													setValue={(e) => setOther1(e)}
													placeholder='Please describe here'
													name='other'
													value={other1}
												/>
											</div>
										</div>

									</>
										: null}


									<div className='mt-5'>
										<label
											className="block font-medium text-gray-700 mb-2">
											Description on the Source of Wealth
										</label>
										<div>
											<label className="checktainer flex mr-5">Savings from regular income
												<input className="checkbox mr-3 ml-2 mt-1"
													type="checkbox"
													checked={savingsWealth}
													onChange={() => setSavingsWealth(!savingsWealth)} />
												<span className="checkmark block text-sm mb-3 font-medium text-primary-700"></span>
											</label>
											<label className="checktainer flex mr-5">Profits from previous investments
												<input className="checkbox mr-3 ml-2 mt-1"
													type="checkbox"
													checked={profitsWealth}
													onChange={() => setProfitsWealth(!profitsWealth)} />
												<span className="checkmark block text-sm mb-3 font-medium text-primary-700"></span>
											</label>
											<label className="checktainer flex mr-5">Inheritance
												<input className="checkbox mr-3 ml-2 mt-1"
													type="checkbox"
													checked={inheritanceWealth}
													onChange={() => setInheritanceWealth(!inheritanceWealth)} />
												<span className="checkmark block text-sm mb-3 font-medium text-primary-700"></span>
											</label>
											<label className="checktainer flex mr-5">Sale of assets (real estate / art etc.)
												<input className="checkbox mr-3 ml-2 mt-1"
													type="checkbox"
													checked={saleWealth}
													onChange={() => setSaleWealth(!saleWealth)} />
												<span className="checkmark block text-sm mb-3 font-medium text-primary-700"></span>
											</label>
											<label className="checktainer flex mr-5">Other
												<input className="checkbox mr-3 ml-2 mt-1"
													type="checkbox"
													checked={otherWealth}
													onChange={() => setOtherWealth(!otherWealth)} />
												<span className="checkmark block text-sm mb-3 font-medium text-primary-700"></span>
											</label>
										</div>
									</div>

									{otherWealth ? <>
										<div className='flex justify-center mb-3'>
											<div className='w-full mr-5'>
												<Input
													setValue={(e) => setOther2(e)}
													placeholder='Please describe here'
													name='other'
													value={other2}
												/>
											</div>
										</div>

									</>
										: null}

									<div className='mt-5'>
										<label
											className="block font-medium text-gray-700 mb-2">
											Intended use of assets
										</label>
										<div>
											<label className="checktainer flex mr-5"> Investment
												<input className=" checkbox mr-3 ml-2 mt-1"
													type="checkbox"
													checked={investmentIntend}
													onChange={() => setInvestmentIntend(!investmentIntend)} />
												<span className="checkmark block text-sm mb-3 font-medium text-primary-700"></span>
											</label>
											<label className="checktainer flex mr-5"> Other
												<input className="checkbox mr-3 ml-2 mt-1"
													type="checkbox"
													checked={otherIntend}
													onChange={() => setOtherIntend(!otherIntend)} />
												<span className="checkmark block text-sm mb-3 font-medium text-primary-700"></span>
											</label>
										</div>
									</div>

									{otherIntend ? <>
										<div className='flex justify-center mb-3'>
											<div className='w-full mr-5'>
												<Input
													setValue={(e) => setOther3(e)}
													placeholder='Please describe here'
													name='other'
													value={other3}
												/>
											</div>
										</div>

									</>
										: null}

									{error !== "" ? <p className="mt-2 text-sm text-red-600" id="email-error">
										{error}
									</p> : null}

									<div className='flex justify-between mt-10'>
										<div>
											<Button
												type='submit'
												full
												loading={loading}
												label='Back'
												onClick={() => goBack()}
											/>
										</div>
										<div>
											<Button
												type='submit'
												full
												loading={loading}
												label='Next'
												onClick={() => onSubmit()}
											/>
										</div>
									</div>

								</form>
							</div>
						</div>
					</div>
				</div >
				: null}
		</div >
	)
}
