import StepItem from "./StepItem";

export default function WorkflowSteps(props) {
	return (
		<>
			<nav aria-label="Progress">
				<ol className="border-gray-200 flex justify-between">
					<StepItem
						label="Personal"
						number="1"
						current={
							props.current === "personal"
						}
						done={
							props.personal
						}
						last={false}
					></StepItem>
					<StepItem
						label="Additional"
						number="2"
						current={
							props.current === "additional"
						}
						done={
							props.additional
						}
						last={false}
					></StepItem>
					<StepItem
						label="KYC"
						number="3"
						last={true}
						current={
							props.current === "KYC"
						}
						done={
							props.kyc
						}></StepItem>
				</ol>
			</nav>
		</>
	)
}