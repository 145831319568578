import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import LoginPage from '@/pages/login'
import NotFoundPage from '@/pages/not-found'
import Dashboard from '@/pages/dashboard'
import { ToastContainer } from 'react-toastify'
import { AuthContext, AuthContextProvider } from '@/context/auth'
import { LoginContext, LoginContextProvider } from '@/context/login'
import { useEffect, useState, useContext } from 'react'

function AuthCore(props) {
	const [, authDispatch] = useContext(AuthContext)
	const [, loginDispatch] = useContext(LoginContext)
	const [ready, setReady] = useState(false)


	useEffect(() => {
		return () => { }
	}, [])

	return ready ? props.children : null
}

function App() {
	return (
		<AuthContextProvider>
			<LoginContextProvider>
				<Router>
					<Switch>
						<Route exact path='/' component={Dashboard} />
						<Route path='*' component={NotFoundPage} />
					</Switch>
				</Router>
				<ToastContainer
					position='top-right'
					autoClose={4000}
					hideProgressBar={false}
					newestOnTop
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</LoginContextProvider>
		</AuthContextProvider>
	)
}

export default App
