import { LoginContext } from '@/context/login'
import { useContext, useState, useEffect } from 'react'
import Input from '@/components/ui/Input'
import Button from '@/components/ui/Button'
import { useForm } from 'react-hook-form'
import { faEnvelope } from '@fortawesome/pro-duotone-svg-icons'
import AuthService from '@/services/auth'
import AuthProcess from '@/components/auth/AuthProcess'
import Alert from '@/components/ui/Alert'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useHistory } from 'react-router-dom'

export default function InitLogin() {
	const [, loginDispatch] = useContext(LoginContext)
	const [loading, setLoading] = useState(false)
	const [accountType, setAccountType] = useState('INDIVIDUAL')
	const [error, setError] = useState(null)
	const { executeRecaptcha } = useGoogleReCaptcha()
	const { register, handleSubmit, errors } = useForm()
	const router = useHistory()
	const query = new URLSearchParams(router.location.search)
	const key = query.get('key')

	async function Submit() {
		setError(null)
		setLoading(true)

		const recaptchaToken = await executeRecaptcha('login_page')

		const config = {
			headers: {
				Recaptcha: recaptchaToken,
			},
		}

		const body = {
			key: key,
		}

		await AuthService.Signup(body, config)
			.then(() => {
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							email: body.email,
							code: '',
							account_type: accountType,
						},
						currentComponent: <AuthProcess></AuthProcess>,
					},
				})
			})
			.catch((err) => {
				setLoading(false)
				setError(err.message)
			})
		return () => { }
	}

	const formSchema = {
		email: {
			required: 'Email required',
			pattern: {
				value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
				message: 'Invalid email',
			},
		},
	}

	useEffect(() => {
		if (executeRecaptcha !== undefined) {
			Submit()
		}

		return () => { }
	}, [executeRecaptcha])

	return (
		<>
			{error ? <Alert type='error' title={error}></Alert> : null}
			<form onSubmit={handleSubmit(Submit)}>
				<div className='mb-5'>
					<p className='text-primary-500'>Wait for authentification ...</p>
					{/* <Input
						icon={faEnvelope}
						label='Email'
						value={email}
						setValue={(e) => setEmail(e)}
						referrer={register(formSchema.email)}
						placeholder='john.doe@synaps.io'
						name='email'
						error={errors.email}
						message={errors.email?.message}
					/> */}
				</div>
				<div>
					{/* <Button
						type='submit'
						full
						loading={loading}
						label='Sign Up'
					/> */}
				</div>
			</form>
		</>
	)
}
