export default function Footer() {
	return (
		<div className='flex justify-between mt-6 mb-6'>
			<div>
				<p className='text-xs relative top-2px ml-4 text-priamry-500 opacity-50 hover:opacity-100 transition duration-200 ease-in'>
					&copy; 2022 - All rights reserved
				</p>
			</div>
			<div>
				<p className='text-xs mr-4'>
					<a target="_blank" rel="noreferrer" href="https://synaps.io/">
						<img
							className='h-5 opacity-50 hover:opacity-100 cursor-pointer transition duration-200 ease-in'
							src='https://storage.googleapis.com/synaps-static-assets/Logo-Synaps_NOIR.jpeg'></img>
					</a>
				</p>
			</div>
		</div>
	)
}
